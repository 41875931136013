import * as React from 'react'
import { Avatar } from "antd";
import * as _ from 'lodash'
import * as Colors from '../../../color'
export const fomatRows = (rewards = []) => {
    const distinctReward = [...new Set(rewards.map(r => r.rewardName))]
    //reward state init
    let state = distinctReward.reduce((obj, v) => { obj[v] = {}; return obj; }, {})
    distinctReward.forEach(rs => {
        let totalCount = 0, rewardPhoto = null, rewardName = null
        let result = []
        //filter and group user in each reward[claimed,redeem]
        rewards.filter(r => r.rewardName === rs)
            .reduce((res, val) => {
                let claimed = 0, redeem = 0
                rewardPhoto = val.rewardPhoto
                rewardName = rewardName === null && val.rewardName
                if (val.status === 'claimed') claimed += 1
                if (val.status !== 'claimed') redeem += 1
                totalCount++
                if (!res[val.memberId]) {
                    res[val.memberId] = { memberId: val.memberId, name: val.memberName, claimed: claimed, redeem: redeem }
                    result.push(res[val.memberId])
                } else {
                    res[val.memberId].claimed += claimed
                    res[val.memberId].redeem += redeem
                }
                return res
            }, {})
        state = {
            ...state,
            [rs]: {
                photo: rewardPhoto,
                name: rewardName,
                totalCount: totalCount,
                member: result.map(r => {
                    return {
                        memberName: r.name,
                        memberId: r.memberId,
                        claimed: r.claimed,
                        redeem: r.redeem,
                        totalCount: r.claimed + r.redeem
                    }
                })
            }
        }
    })
    console.log('reward state table format', state)
    return state
}
export const createRows = (rewards = []) => {
    let i = 0
    const stateObj = fomatRows(rewards)
    const rows = Object.keys(stateObj)

    return rows.length > 0 ?
        _.orderBy(rows.map(rowKey => {
            i++
            return {
                key: i,
                name: rowKey,
                photo: stateObj[rowKey]['photo'],
                count: stateObj[rowKey]['totalCount'] || 0,
                member: stateObj[rowKey]['member'] || [],
            }
        }), 'count', 'desc')
        : []
}
export const createColumns = () => {
    const columns = [
        {
            title: 'Photo',
            key: 'photo',
            render: ({ photo }) => photo ? <Avatar src={photo} size={200} shape='square' /> : <Avatar icon='gift' size={70} style={{ color: 'black', backgroundColor: 'white' }} />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: text => <b style={{ color: 'black', fontSize: 20 }} >{text}</b>
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            width: '100px',
            render: text => <b style={{ color: 'black', fontSize: 20 }} >{text}</b>
        },
        {
            title: 'Members Reward',
            key: 'member',
            //width:'250px',
            fixed: 'right',
            render: ({ member = [] }) => <table style={{
                display: 'table',
                borderCollapse: 'separate',
                borderSpacing: '2px',
                borderColor: 'gray',
                height: '20px',
                // overflow: 'scroll',
            }}>
                <thead style={{ display: 'block' }}>
                    <tr key='0'>
                        <th style={{ color: 'black', width: '150px' }}>Member</th>
                        <th style={{ color: 'black', width: '150px' }}>Total Count</th>
                        <th style={{ color: 'black', width: '150px' }}>Claimed</th>
                        <th style={{ color: 'black', width: '150px' }}>Redeem</th>
                    </tr>
                </thead>
                <tbody style={{
                    height: '150px',
                    display: 'block',
                    width: '100%',
                    overflowY: 'scroll'
                }} >
                    {member.map((m, index) => (
                        <tr key={index}>
                            <td style={{ width: '150px', color: Colors.LabelColor, fontWeight: 'bold' }}>{m.memberName}</td>
                            <td style={{ width: '150px', color: 'black', fontWeight: 'bold' }}>{m.totalCount}</td>
                            <td style={{ width: '150px', color: 'black', fontWeight: 'bold' }}>{m.claimed}</td>
                            <td style={{ width: '150px', color: 'black', fontWeight: 'bold' }}>{m.redeem}</td>
                        </tr>
                    ))}
                </tbody>
            </table >
        },
    ]
    return columns

}
