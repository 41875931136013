const debug = false

export const Console = (msg: string) => {
    return {
        log: () => {
            if (debug) console.log(`[Log] : ${msg}`)
        },
        error: () => {
            if (debug) console.error(`[Error] : ${msg}`)
        },
        info: () => {
            if (debug) console.info(`[Info] : ${msg}`)
        }
    }
}
