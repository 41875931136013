import firebase from 'firebase/app';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { destroy } from 'redux-form';
import { AppState, UserInfo } from '../../store';
import * as actions from '../actions';
import LoginForm from '../components/LoginForm2';
const locationHelper = locationHelperBuilder({});

const destroyLoginForm = () => destroy('LoginForm');

const mapStateToProps = (
  { authState }: AppState,
  ownProps: RouteComponentProps<{}>
) => {
  const redirect = locationHelper.getRedirectQueryParam(ownProps) || '/';

  if (authState.status === 'loading') {
    return {
      authenticating: true,
      isAuthenticated: false,
      redirect,
      error: null,
    };
  }

  return {
    isAuthenticated: authState.status === 'loggedIn',
    authenticating: false,
    error: authState.status === 'error' ? authState.error || '' : null,
    redirect,
  };
};

const mapDispatchToProps = (dispatch: Dispatch, { history }: Props) => {
  const { loggedIn, loggedOut } = actions;
  return {
    replace: (route: string) => {
      history.replace(route);
    },
    loggedIn: (user: firebase.User, userInfo: UserInfo) => {
      dispatch(loggedIn(user, userInfo));
      dispatch(destroyLoginForm());
    },
    loggedOut: () => {
      dispatch(loggedOut())
    }
  };
};

interface Props extends RouteComponentProps<{}> {
  isAuthenticated: boolean;
  authenticating: boolean;
  error: string | null;
  redirect: string;
  replace: (route: string) => void;
  loggedIn: (user: firebase.User, userInfo: UserInfo) => void;
  loggedOut: () => void;
}

class Login extends React.Component<Props> {
  subscription: firebase.Unsubscribe;

  constructor(props: Props) {
    super(props);
    this.subscription = () => { };
  }

  componentDidMount() {
    const { isAuthenticated, replace, redirect, loggedIn, loggedOut } = this.props;
    if (isAuthenticated) {
      replace(redirect);
    }
    this.subscription = firebase.auth().onAuthStateChanged(async (u) => {
      if (u) {
        const { claims } = await u.getIdTokenResult()
        const info = claims as UserInfo
        const auth = firebase.functions().httpsCallable('authMerchantWeb');
        const result = await auth({ merchantId: info.merchantId, username: info.username, password: info.password });
        const authResult = result.data;
        if (authResult.success) {
          loggedIn(u, info);
        } else {
          loggedOut()
        }
      }
    });
  }
  componentWillReceiveProps(nextProps: Props) {
    const { isAuthenticated, replace, redirect } = nextProps;
    const { isAuthenticated: wasAuthenticated } = this.props;

    if (!wasAuthenticated && isAuthenticated) {
      replace(redirect);
    }
  }

  componentWillUnmount() {
    this.subscription();
  }

  render() {
    return (
      <div className="App">
        <div className="login-bg">
          <div className="login-box">
            <div className="login-logo">
              <img src="/logo.png" />
              <h2>CarsNet Admin</h2>
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
