import firebase from 'firebase/app';
import { Action, AuthState, UserInfo } from '../store';

export const actionTypes = {
  LOGIN_REQUIRE: 'auth/LOGIN_REQUIRE',
  LOGIN_REQUEST: 'auth/LOGIN_REQUEST',
  LOGIN_REQUEST_FAILED: 'auth/LOGIN_REQUEST_FAILED',
  LOGOUT_REQUEST: 'auth/LOGOUT_REQUEST',
  LOGGED_OUT: 'auth/LOGGED_OUT',
  LOGGED_IN: 'auth/LOGGED_IN',
};

export interface LoginRequestPayload {
  username: string;
  password: string;
  nextRoute: string;
}

const {
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILED,
  LOGGED_IN,
  LOGGED_OUT,
} = actionTypes;

type LoggedAction = Action<{ user: firebase.User } | { error: string }>;
const defaultUser = { status: 'none' as 'none' };
export const authReducer = (
  state: AuthState = defaultUser,
  action: LoggedAction
) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        status: 'loading',
      };
    }
    case LOGGED_IN:
      const { user, userInfo } = action.payload as { user: firebase.User, userInfo: UserInfo };
      return {
        status: 'loggedIn',
        user: user,
        userInfo: userInfo,
      };
    case LOGIN_REQUEST_FAILED:
      const { error } = action.payload as { error: string };
      return {
        status: 'error',
        error,
      };
    case LOGGED_OUT:
      firebase.auth().signOut();
      return defaultUser;
    default:
      break;
  }
  return state;
};
