import * as Type from './actionType'
import { Console } from "../../console";
const initState = {
    summary: {
        totalMember: 0,
        earnPoint: 0,
        spendPoint: 0,
        earnEngage: 0,
        spendEngage: 0,
    },
    chart: {
        member: {
            trend: [],
            topMember: []
        },
        point: {
            trend: [],
            monthly: [],
            lastMonth: []
        }
    },
    fetchedSummary: false,
    fetchedMemberTrend: false,
    fetchedPointTrend: false,
    fetchedTopMember: false,
    fetchedPointMonthly: false,
    fetchedLastMonth: false
}
export const chartReducer = (state = initState, action) => {
    Console(`chart state; ${state}`).log()
    switch (action.type) {
        case Type.ADD_SUMMARY_POINT_MEMBER:
            const { totalMember = 0, spendPoint = 0, earnPoint = 0, earnEngage = 0, spendEngage = 0 } = action.payload
            return {
                ...state,
                summary: {
                    ...state.summary,
                    totalMember: totalMember,
                    spendPoint: spendPoint,
                    earnPoint: earnPoint,
                    spendEngage: spendEngage,
                    earnEngage: earnEngage,
                },
                fetchedSummary: true
            }
        case Type.ADD_MEMBER_TREND: {
            const { memberTrend = [] } = action.payload
            return {
                ...state,
                chart: {
                    ...state.chart,
                    member: {
                        ...state.chart.member,
                        trend: memberTrend
                    }
                },
                fetchedMemberTrend: true
            }
        }
        case Type.ADD_TOP_MEMBER: {
            const { topMember = [] } = action.payload
            return {
                ...state,
                chart: {
                    ...state.chart,
                    member: {
                        ...state.chart.member,
                        topMember: topMember
                    }
                },
                fetchedTopMember: true
            }
        }
        case Type.ADD_POINT_TREND: {
            const { pointTrend = [] } = action.payload
            return {
                ...state,
                chart: {
                    ...state.chart,
                    point: {
                        ...state.chart.point,
                        trend: pointTrend
                    }
                },
                fetchedPointTrend: true
            }
        }
        case Type.ADD_MONTLY_POINT_MEMER: {
            const { pointMonthly = [] } = action.payload
            return {
                ...state,
                chart: {
                    ...state.chart,
                    point: {
                        ...state.chart.point,
                        monthly: pointMonthly
                    }
                },
                fetchedPointMonthly: true
            }
        }
        case Type.ADD_LASTMONTH_POINT: {
            const { pointLastMonth = [] } = action.payload
            return {
                ...state,
                chart: {
                    ...state.chart,
                    point: {
                        ...state.chart.point,
                        lastMonth: pointLastMonth
                    }
                },
                fetchedLastMonth: true
            }
        }
        default:
            return state
    }
}