import * as Type from './actionTypes'
import config from '../../config'
import { Console } from "../../console";
const report_url = process.env.REPORT_URL || config().config.Report.url

export const fetchRewardHistory = (merchantId) => dispatch => {
    let _url = `${report_url}/history/reward/${merchantId}`
    Console(`Report reward url :${_url}`).log()
    fetch(_url, {
        crossDomain: true,
    })
        .then(response => response.json())
        .then(data => {
            Console(`Get reward history data", ${data}`)
            let _action = {}
            _action = { ..._action, type: Type.ADD_REWARD_HISTORY, payload: [] }
            if (data.status !== 'Error' || data.data.length < 0) {
                _action = { ..._action, payload: data.data || [] }
            }
            dispatch(_action)
        });
}


//expected parms milliseconds [from ,to ]
export const addRewardFilterRange = (from, to) => dispatch => {
    const _action = {
        type: Type.ADD_REWARD_FILTER_RANGE,
        payload: {
            from: from,
            to: to
        }
    }
    dispatch(_action)
}

//expected params Array [filterType]
export const addRewardFilterType = (filterType = []) => (dispatch) => {
    const _action = {
        type: Type.ADD_REWARD_FILTER_TYPE,
        payload: filterType
    }
    dispatch(_action)
}
export const clearRewardFilter = () => (dispatch) => {
    const _action = {
        type: Type.CLEAR_REWARD_FILTER
    }
    dispatch(_action)
}