import React, { Component } from 'react'
import { Card, Row, Col, Icon, Table } from 'antd'
import { connect } from 'react-redux';
import { DetailModel } from "../model";
import LineChart from './Chart/LineChart'
import RewardTable from './Reward/reward'
import * as Actions from '../../redux/actions'
import nophoto from './noprofile.png'
const { GetMemberDetail, lineChartConfig, createRows, crateColumns, carsRow, carsColumns } = DetailModel
const { Meta } = Card
class MemberDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            pending: false,
            member: {},
            rewards: [],
            points: [],
            cars: [],
        }
        this.init = this.init.bind(this)
    }
    init(props) {
        const { fetchedMember, getAllMember, merchantId, memberData } = props
        const { fetchedRootPoint, getAllPoint, pointData } = props
        const { fetchedReward, rewardData, getAllReward } = props
        const { fetchedCar, cars, getAllCar } = props
        const { fetchedRootPointCount, totalCount, getAllPointCount } = props
        if (!fetchedReward) getAllReward(merchantId)
        if (!fetchedMember) getAllMember(merchantId)
        if (!fetchedRootPointCount) getAllPointCount(merchantId)
        if (!fetchedCar) getAllCar()
        if (fetchedRootPointCount && !fetchedRootPoint && !this.state.pending) {
            this.setState({
                pending: true
            }, () => {
                getAllPoint(merchantId, totalCount)
            })
        }
        if (fetchedReward && fetchedMember && fetchedRootPoint && fetchedCar) {
            const { memberId } = this.props.match.params
            if (memberId !== undefined) {
                let newObj = { members: memberData, points: pointData, rewards: rewardData, memberId: memberId, cars: cars }
                let state = GetMemberDetail({ ...newObj })
                this.setState({
                    loading: true,
                    member: state.member,
                    rewards: state.rewards,
                    points: state.points,
                    cars: state.cars
                })
            }
        }
    }

    componentDidMount() {
        this.init(this.props)
    }
    componentWillReceiveProps(nextProps) {
        this.init(nextProps)
    }
    render() {
        return (
            <Card title='Member Detail' loading={!this.state.loading}>
                <div className="member__detail_bar">
                    <Row gutter={24} >
                        <Col span={9}>
                            <Card
                                cover={
                                    <div style={{ display: 'flex' }}>
                                        <div key='1' className="user__profile" style={{ width: 300, height: 350 }}>
                                            {
                                                <img
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        width: '100%',
                                                        height: '100%',
                                                        borderRadius: '3%'
                                                    }}
                                                    alt="example" src={this.state.member.photo ? this.state.member.photo : nophoto} />
                                            }
                                        </div>
                                        <div key='2' className="user__info" style={{ marginLeft: '20px' }}>
                                            <div key='21' style={{ display: 'flex', width: 200 }}>
                                                <i style={{ fontSize: 14, fontWeight: 'bold', color: 'black' }}>Join Since: </i>
                                                <i style={{ marginLeft: 'auto' }}>{this.state.member.joinedAt}</i>
                                            </div>
                                            <div key='22' style={{ display: 'flex', marginTop: '15px' }}>
                                                <i style={{ fontSize: 14, fontWeight: 'bold', color: 'black' }}>First Order: </i>
                                                <i style={{ marginLeft: 'auto' }}>{this.state.member.firstActive}</i>
                                            </div>
                                            <div key='23' style={{ display: 'flex', marginTop: '15px' }}>
                                                <i style={{ fontSize: 14, fontWeight: 'bold', color: 'black' }}>Last Order: </i>
                                                <i style={{ marginLeft: 'auto' }}>{this.state.member.lastActive}</i>
                                            </div>
                                            <div key='24' style={{ display: 'flex', marginTop: '15px' }}>
                                                <i style={{ fontSize: 14, fontWeight: 'bold', color: 'black' }}>Label Order: </i>
                                                <i style={{ marginLeft: 'auto' }}></i>
                                            </div>
                                            <div key='25' style={{ display: 'flex', marginTop: '15px' }}>
                                                <i style={{ fontSize: 14, fontWeight: 'bold', color: 'black' }}>Address : </i>
                                                <i style={{ marginLeft: 'auto' }}></i>
                                            </div>
                                            <div key='27' style={{ display: 'flex', marginTop: '30px' }}>
                                                <i style={{ fontSize: 14, fontWeight: 'bold', color: 'black' }}>Current Point: </i>
                                                <i style={{ marginLeft: 'auto' }}>{this.state.member.memberPoint}</i>
                                            </div>
                                            <div key='28' style={{ display: 'flex', marginTop: '15px' }}>
                                                <i style={{ fontSize: 14, fontWeight: 'bold', color: 'black' }}>Total Point : </i>
                                                <i style={{ marginLeft: 'auto' }}>{this.state.member.totalPoint}</i>
                                            </div>
                                            <div key='29' style={{ display: 'flex', marginTop: '15px' }}>
                                                <i style={{ fontSize: 14, fontWeight: 'bold', color: 'black' }}>Total Engagememt : </i>
                                                <i style={{ marginLeft: 'auto' }}>{this.state.member.pF}</i>
                                            </div>
                                        </div>
                                    </div>

                                }
                            >
                                <Meta
                                    title={this.state.member.displayName}
                                    description={[
                                        <div key='3' style={{ display: 'flex', }}>
                                            <Icon type="mail" style={{ color: 'black' }} />
                                            <i style={{ marginLeft: '12px' }}> {this.state.member.email}</i>
                                            <Icon type="phone" style={{ color: 'black', marginLeft: '40px' }} />
                                            <i style={{ marginLeft: '12px' }}> {this.state.member.phone}</i>
                                        </div>,
                                    ]}
                                />
                            </Card>
                        </Col>
                        <Col span={15}>
                            <Card>
                                <LineChart config={lineChartConfig({ points: this.state.points })} />
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="member__detail_body" style={{ marginTop: '12px' }}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Card title='Point History'>
                                <Table
                                    size='small'
                                    columns={crateColumns()}
                                    dataSource={createRows(this.state.points)}
                                    scroll={{ x: 1500 }}
                                    pagination={{ pageSize: 10 }}
                                    sticky
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title="Reward History">
                                <RewardTable rewards={this.state.rewards} />
                            </Card>
                        </Col>

                    </Row>

                </div>
                <div className="mebmer_detail_two" style={{ marginTop: '10px' }}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Card title='Cars'>
                                <Table
                                    size='small'
                                    columns={carsColumns()}
                                    dataSource={carsRow(this.state.cars)}
                                    scroll={{ x: 1500 }}
                                    pagination={{ pageSize: 10 }}
                                    sticky
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Card>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllMember: (merchantId) => dispatch(Actions.GetAllMember(merchantId)),
        getAllPoint: (merchantId, totalCount) => dispatch(Actions.GetAllPoint(merchantId, totalCount)),
        getAllReward: (merchantId) => dispatch(Actions.fetchRewardHistory(merchantId)),
        getAllCar: () => dispatch(Actions.fetchCars()),
        getAllPointCount: (merchantId) => dispatch(Actions.GetAllTotalPointCount(merchantId)),
        getInactiveMember: ({ members, points, rewards, from, to }) => dispatch(Actions.GetInactiveMembers({ members, points, rewards, from, to }))
    }
}
const mapStateToProps = state => {
    const { authState, reportState } = state
    const { member, fetchedMember, fetchedRootPoint, fetchedInactiveMember, root } = reportState
    const { totalCount, fetchedRootPointCount, point } = root
    const { fetchedReward, history, car, fetchedCar } = reportState
    const { cars } = car
    const { reward } = history
    const [rewardData] = [reward.origin]
    const { origin, inactive } = member
    return {
        pointData: point,
        memberData: origin,
        inactiveMemberData: inactive,
        totalCount: totalCount,
        rewardData: rewardData,
        fetchedCar: fetchedCar,
        cars: cars,
        fetchedMember: fetchedMember,
        fetchedReward: fetchedReward,
        fetchedRootPoint: fetchedRootPoint,
        fetchedRootPointCount: fetchedRootPointCount,
        fetchedInactiveMember: fetchedInactiveMember,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberDetail)
