import * as React from 'react'
import moment from 'moment'
import { DatePicker, Button } from 'antd'
const { RangePicker } = DatePicker
const dateFormat = 'YYYY/MM/DD';

const DateRange = ({ defaultValue, size, onChange, onOk, className, footerController }) => {
    return (
        <RangePicker
            className={className}
            format={dateFormat}
            size={'middle'}
            placeholder={['start date', 'end date']}
            defaultPickerValue={defaultValue}
            defaultValue={defaultValue}
            onChange={(s, t) => onChange(s, t)}
            onOk={() => onOk()}
            allowClear={true}
            autoFocus={true}
            renderExtraFooter={() => {
                return (
                    <>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment(), moment()])}>Today</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment().startOf('month').format(dateFormat), moment().endOf('month').format(dateFormat)])}>This Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment().subtract(7, 'day'), moment()])}>Last 1Week</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment().subtract(14, 'day'), moment()])}>Last 2Week</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment().subtract(21, 'day'), moment()])}>Last 3Week</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment().subtract(1, 'month'), moment()])}>Last 1Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment().subtract(3, 'month'), moment()])}>Last 3Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment().subtract(6, 'month'), moment()])}>Last 6Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment().subtract(9, 'month'), moment()])}>Last 9Month</Button>
                        <Button style={{ marginLeft: 10 }} type='primary' size='small' onClick={() => footerController({}, [moment().subtract(12, 'month'), moment()])}>Last Year</Button>
                    </>
                )
            }
            }
            showTime
        />
    )

}
export default DateRange