import { Button, Icon } from 'antd';
import * as React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { TextField } from 'redux-form-antd';
import { PasswordField } from '../../reduxFormAntd';
import { required } from '../../reduxFormAntd/validations';

export interface FormData {
  merchantId: string;
  username: string;
  password: string;
}
type P = InjectedFormProps<FormData>;

const UserNamePasswordForm = reduxForm<FormData>({
  form: 'LoginForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(
  class extends React.Component<P> {
    render() {
      const { handleSubmit, error, submitting } = this.props;
      return (
        <form className="ant-form ant-form-horizontal" onSubmit={handleSubmit}>
          {/* <Field
            validate={[required]}
            prefix={<Icon type="shop" />}
            name="merchantId"
            autoComplete="merchantId"
            component={TextField}
            placeholder="Merchant ID"
          /> */}
          <Field
            validate={[required]}
            prefix={<Icon type="user" />}
            name="username"
            autoComplete="username"
            component={TextField}
            placeholder="Login Name"
          />
          <Field
            validate={[required]}
            prefix={<Icon type="lock" />}
            name="password"
            autoComplete="current-password"
            component={PasswordField}
            placeholder="Password"
            hasFeedback={false}
          />
          <Button
            icon="right"
            htmlType="submit"
            loading={submitting}
            type="primary"
          >
            Login
          </Button>
          <div className="login-error">
            {error && <span>Invalid Authenticate Or Disable Web Portal </span>}
          </div>
        </form>
      );
    }
  }
);

export default UserNamePasswordForm;
