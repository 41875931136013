import * as Colors from '../../../color'
import * as _ from 'lodash'
export const BarChartConfig = ({ rewards = [], members = [] }) => {
  const distinctReward = [...new Set(rewards.map(r => r.rewardName))]
  var data = Array(distinctReward.length).fill().map(p => Array(2).fill().map(p => p))
  var max = 0
  distinctReward.forEach((rw, index) => {
    var totalCount = 0
    rewards.filter(r => r.rewardName === rw).map(r => totalCount++)
    data[index][0] = rw
    data[index][1] = totalCount
    max = totalCount > max ? totalCount : max
  })

  max += 50
  data = data.sort((a, b) => b[1] - a[1])
  return {
    chart: {
      type: 'bar',
      marginLeft: 150
    },
    title: {
      text: 'Reward Count'
    },
    // subtitle: {
    //     text: 'Source: <a href="https://highcharts.uservoice.com/forums/55896-highcharts-javascript-api">UserVoice</a>'
    // },
    xAxis: {
      type: 'category',
      title: {
        text: null
      },
      color: 'green',
      min: 0,
      max: 5,
      scrollbar: {
        enabled: true
      },
      tickLength: 0
    },
    yAxis: {
      min: 0,
      max: max,
      title: {
        text: 'reward',
        align: 'high'
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        }
      }
    },
    colors: [Colors.BannaColor, Colors.WatermelonColor],
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Rewards',
      data: data
    }]
  }
}

export const pieConfig = ({ rewards = [] }) => {
  var pieObj = { rewards: 0, claimed: 0, redeem: 0 }
  var totalRewards = 0, totalClaimed = 0, totalRedeem = 0
  const distinctReward = [...new Set(rewards.map(r => r.rewardName))]
  totalRewards = distinctReward.length
  totalClaimed = rewards.filter(r => r.status === 'claimed').length
  totalRedeem = rewards.filter(r => r.status === null).length
  pieObj = { ...pieObj, rewards: totalRewards, claimed: totalClaimed, redeem: totalRedeem }
  const pieSerie = [{
    name: 'Total',
    colorByPoint: true,
    data: [{
      name: 'Reward',
      y: pieObj.rewards,
      color: Colors.BannaColor,
      sliced: true,
      selected: true
    }, {
      name: 'Redeem',
      color: Colors.CantelColor,
      y: pieObj.redeem
    }, {
      name: 'Claimed',
      color: Colors.ColorOne,
      y: pieObj.claimed
    },]
  }]
  const config = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      defaultSeriesType: 'areaspline'
    },
    title: {
      text: 'Reward Summary'
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        size: '100%',
        dataLabels: {
          enabled: false,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        },
        showInLegend: true,
      }
    },
    series: pieSerie
  }
  return config
}
export const treeMapConfig = ({ rewards = [], members = [] }) => {
  const distinctReward = [...new Set(rewards.map(r => r.rewardName))]
  var rewardStateObj = distinctReward.map((r, index) => {
    return {
      id: `${index + 1}`,
      name: r,
      color: Colors.RandomColor()
    }
  })
  let zeroLevel = { id: '0', name: 'Zero', color: 'blue' }
  let data = [...rewardStateObj]
  data.push(zeroLevel)
  rewardStateObj.forEach(rs => {
    let result = []
    rewards.filter(r => r.rewardName === rs.name && r.status === "claimed")
      .reduce((res, val) => {
        if (!res[val.memberId]) {
          res[val.memberId] = { memberId: val.memberId, count: 0, name: val.memberName }
          result.push(res[val.memberId])
        }
        res[val.memberId].count += 1
        return res
      }, {})

    result.map(res => data.push({ name: res.name, parent: rs.id, value: res.count }))
  })

  return {
    series: [{
      type: "treemap",
      layoutAlgorithm: 'stripes',
      alternateStartingDirection: true,
      levels: [{
        level: 1,
        layoutAlgorithm: 'squarified',
        dataLabels: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          style: {
            fontSize: '15px',
            fontWeight: 'bold'
          }
        }
      },
      {
        level: 2,
        layoutAlgorithm: 'stripes',
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '10px',
            fontWeight: 'normal',
            textOutline: false
          }
        }

      },
      ],
      data: data
    }],
    title: {
      text: 'Reward Members'
    },
    credits: {
      enabled: false
    },
  }

}
export const rewardBarChartList = ({ rewards = [] }) => {
  const distinctReward = [...new Set(rewards.map(r => r.rewardName))]
  var stateList = []
  distinctReward.forEach(rs => {
    let result = []
    //filter and group user in each reward
    rewards.filter(r => r.rewardName === rs && r.status === "claimed")
      .reduce((res, val) => {
        if (!res[val.memberId]) {
          res[val.memberId] = { memberId: val.memberId, count: 0, name: val.memberName }
          result.push(res[val.memberId])
        }
        res[val.memberId].count += 1
        return res
      }, {})
    // make two-dimention array 
    var data = Array(result.length).fill().map(p => Array(2).fill().map(p => p))
    var max = 0
    result.forEach((rm, index) => {
      data[index][0] = rm.name
      data[index][1] = rm.count
      max = rm.count > max ? rm.count : max
    })
    let sortMax = max
    max += 50
    data = data.sort((a, b) => b[1] - a[1])
    // config chart format 
    const config = {
      chart: {
        type: 'bar',
        marginLeft: 150
      },
      title: {
        text: rs
      },
      xAxis: {
        type: 'category',
        title: {
          text: null
        },
        min: 0,
        max: 5,
        scrollbar: {
          enabled: true
        },
        tickLength: 0
      },
      yAxis: {
        min: 0,
        max: max,
        title: {
          text: 'reward',
          align: 'high'
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      colors: [Colors.RandomColor(), Colors.RandomColor()],
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Rewards',
        data: data
      }]
    }
    stateList.push({ ...config, max: sortMax })
  })
  console.log('stateList Config', stateList)
  return _.orderBy(stateList, 'max', 'desc')
}