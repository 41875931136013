import * as Type from './actionTypes'
import { Console } from "../../console";
import moment from 'moment'
const initState = {
    root: {
        point: [],
        totalCount: 0,
        fetchedRootPointCount: false
    },
    member: {
        origin: [],
        temp: [],
        filter: {
            from: 0,
            to: 0
        },
        active: [],
        inactive: [],
        red: [],
        sleep: [],
        risk: [],
        royal: [],
        returning: [],
        news: [],
        top: []
    },
    fetchedMember: false,
    auditLog: {
        origin: [],
        temp: [],
        filter: {
            from: 0,
            to: 0,
            type: []
        },
    },
    history: {
        stamp: {
            origin: [],
            temp: [],
            filter: {
                from: 0,
                to: 0,
                type: [],
            }
        },
        reward: {
            origin: [],
            temp: [],
            filter: {
                from: 0,
                to: 0,
                type: [],
            }
        },
        point: {
            origin: [],
            temp: [],
            filter: {
                from: 0,
                to: 0,
                type: [],
            }
        },
    },
    expire: {
        point: []
    },
    car: {
        cars: [],
    },
    fetchedCar: false,
    fetchedStamp: false,
    fetchedPoint: false,
    fetchedReward: false,
    fetchedExpirePoint: false,
    fetchedAudit: false,
    fetchedRootPoint: false,
    fetchedRedMember: false,
    fetchedSleepMember: false,
    fetchedRiskMember: false,
    fetchedRoyalMember: false,
    fetchedReturnMember: false,
    fetchedNewMember: false,
    fetchedActiveMember: false,
    fetchedInactiveMember: false,
    fetchedReportTopMember: false
}
export const reportReducer = (state = initState, action) => {
    Console(`rp state:  ${state}`)
    switch (action.type) {
        case Type.ADD_ALL_TOTAL_POINT_COUNT:
            const { totalCount = 0 } = action.payload
            return {
                ...state,
                root: {
                    ...state.root,
                    totalCount: totalCount,
                    fetchedRootPointCount: true
                }
            }
        case Type.ADD_ALL_POINT_RECORDS:
            let arr = state.root.point.concat(action.payload)
            return {
                ...state,
                root: {
                    ...state.root,
                    point: arr
                },
                fetchedRootPoint: arr.length === state.root.totalCount ? true : false
                // fetchedRootPoint: arr.length > 0 ? true : false
            }
        case Type.ADD_EXPIRE_POINT: {
            const { expirePoints } = action.payload
            return {
                ...state,
                expire: {
                    point: expirePoints
                },
                fetchedExpirePoint: true
            }
        }
        case Type.ADD_ALL_MEMBER:
            const { memberList = [] } = action.payload
            return {
                ...state,
                member: {
                    ...state.member,
                    origin: memberList,
                    temp: memberList,
                    filter: {
                        ...state.member.filter,
                        from: 0,
                        to: 0
                    }
                },
                fetchedMember: true
            }
        case Type.ADD_MEMBER_FILTER_RANGE:
            const [mFrom = 0, mTo = 0] = [action.payload.from, action.payload.to]
            return {
                ...state,
                member: {
                    ...state.member,
                    temp: state.member.origin.filter(m => moment(m.joinedAt).unix() * 1000 >= mFrom && moment(m.joinedAt).unix() * 1000 <= mTo),
                    filter: {
                        ...state.member.filter,
                        from: mFrom,
                        to: mTo
                    }
                }
            }
        case Type.CLEAR_MEMBER_FILTER:
            return {
                ...state,
                member: {
                    ...state.member,
                    temp: state.member.origin,
                    filter: {
                        from: 0,
                        to: 0
                    }
                }
            }
        case Type.ADD_ACTIVE_MEMBER:
            const { activeMembers } = action.payload
            return {
                ...state,
                member: {
                    ...state.member,
                    active: activeMembers
                },
                fetchedActiveMember: true
            }
        case Type.ADD_NEW_MEMBER:
            const { newMembers } = action.payload
            return {
                ...state,
                member: {
                    ...state.member,
                    news: newMembers
                },
                fetchedNewMember: true
            }
        case Type.ADD_REPORT_TOP_MEMBER:
            const { topMembers } = action.payload
            return {
                ...state,
                member: {
                    ...state.member,
                    top: topMembers
                },
                fetchedReportTopMember: true
            }
        case Type.ADD_INACTIVE_MEMBER:
            const { inactiveMembers } = action.payload
            return {
                ...state,
                member: {
                    ...state.member,
                    inactive: inactiveMembers
                },
                fetchedInactiveMember: true
            }
        case Type.ADD_RED_ALERT_MEMBER:
            const { redMembers } = action.payload
            return {
                ...state,
                member: {
                    ...state.member,
                    red: redMembers
                },
                fetchedRedMember: true,
            }
        case Type.ADD_SLEEP_MEMBER:
            const { sleepMembers } = action.payload
            return {
                ...state,
                member: {
                    ...state.member,
                    sleep: sleepMembers
                },
                fetchedSleepMember: true,
            }
        case Type.ADD_RISK_MEMBER:
            const { riskMembers } = action.payload
            return {
                ...state,
                member: {
                    ...state.member,
                    risk: riskMembers
                },
                fetchedRiskMember: true,
            }
        case Type.ADD_RETURN_MEMER:
            const { returnMembers } = action.payload
            return {
                ...state,
                member: {
                    ...state.member,
                    returning: returnMembers
                },
                fetchedReturnMember: true,
            }
        case Type.ADD_ROYAL_MEMBER:
            const { royalMembers } = action.payload
            return {
                ...state,
                member: {
                    ...state.member,
                    royal: royalMembers
                },
                fetchedRoyalMember: true,
            }
        case Type.GET_ALL_AUDITLOGS:
            // fetch all logs from db;
            return {
                ...state,
                auditLog: { ...state.auditLog, origin: action.payload, temp: action.payload, filter: initState.auditLog.filter },
                fetchedAudit: action.payload.length > 0 ? true : false
            }
        case Type.ADD_AUDIT_FILTER_RANGE:
            // get audit logs by range 
            const { from, to } = action.payload
            return {
                ...state,
                auditLog: {
                    ...state.auditLog,
                    temp: state.auditLog.origin.filter(audit =>
                        state.auditLog.filter.type.length > 0 ?
                            audit.Date._seconds >= from && audit.Date._seconds <= to && state.auditLog.filter.type.includes(audit.event) :
                            audit.Date._seconds >= from && audit.Date._seconds <= to
                    ),
                    filter: {
                        from: from,
                        to: to,
                        type: state.auditLog.filter.type.length > 0 ? state.auditLog.filter.type : []
                    }
                }
            }
        case Type.ADD_AUDIT_FILTER_TYPE:
            // search audit by type / award-point / award-stamp
            const { auditType = [] } = action.payload
            const { filter } = state.auditLog
            return {
                ...state,
                auditLog: {
                    ...state.auditLog,
                    temp: state.auditLog.origin.filter(audit =>
                        filter.from > 0 && filter.to > 0 ? audit.Date._seconds >= filter.from && audit.Date._seconds <= filter.to && auditType.includes(audit.event) :
                            auditType.includes(audit.event)),
                    filter: {
                        ...state.auditLog.filter, type: auditType
                    }
                }
            }
        case Type.CLEAR_AUDIT_FILTER:
            // clear all filters autdit logs 
            return {
                ...state,
                auditLog: {
                    ...state.auditLog,
                    temp: state.auditLog.origin,
                    filter: initState.auditLog.filter
                }
            }
        case Type.ADD_POINT_HISTORY:
            return {
                ...state,
                history: {
                    ...state.history,
                    point: {
                        ...state.history.point,
                        origin: action.payload,
                        temp: action.payload
                    }
                },
                fetchedPoint: true
            }
        case Type.ADD_POINT_FILTER_RANGE: {
            const { from, to } = action.payload
            return {
                ...state,
                history: {
                    ...state.history,
                    point: {
                        ...state.history.point,
                        temp: state.history.point.origin.filter(p => p.createdAt >= from && p.createdAt <= to),
                        filter: {
                            from: from,
                            to: to
                        }
                    }
                }
            }
        }
        case Type.CLEAR_POINT_FILTER: {
            return {
                ...state,
                history: {
                    ...state.history,
                    point: {
                        ...state.history.point,
                        temp: state.history.point.origin,
                        filter: initState.history.point.filter
                    }
                }
            }
        }
        case Type.ADD_REWARD_HISTORY:
            return {
                ...state,
                history: {
                    ...state.history,
                    reward: {
                        ...state.history.reward,
                        origin: action.payload,
                        temp: action.payload
                    }
                },
                fetchedReward: true
            }
        case Type.ADD_REWARD_FILTER_RANGE:
            let [reward, filters = []] = [state.history.reward, state.history.reward.filter.type]
            let [rFrom, rTo] = [action.payload.from, action.payload.to]
            return {
                ...state,
                history: {
                    ...state.history,
                    reward: {
                        ...reward,
                        temp: reward.origin.filter(r =>
                            filters.length > 0 ? r.createdAt >= rFrom && r.createdAt <= rTo && filters.includes(r.status) :
                                r.createdAt >= rFrom && r.createdAt <= rTo),
                        filter: {
                            from: rFrom,
                            to: rTo,
                            type: filters.length > 0 ? filters : []
                        }
                    }
                }
            }
        case Type.ADD_REWARD_FILTER_TYPE:
            let [_reward, _filter] = [state.history.reward, state.history.reward.filter]
            let [_rFrom, _rTo, _filters] = [_filter.from, _filter.to, action.payload]

            return {
                ...state,
                history: {
                    ...state.history,
                    reward: {
                        ..._reward,
                        temp: _reward.origin.filter(r =>
                            _rFrom > 0 && _rTo > 0 ?
                                r.createdAt >= _rFrom && r.createdAt <= _rTo
                                && _filters.includes(r.status) :
                                _filters.includes(r.status)
                        ),
                        filter: {
                            ..._filter,
                            type: _filters
                        }
                    }

                }
            }
        case Type.CLEAR_REWARD_FILTER:
            return {
                ...state,
                history: {
                    ...state.history,
                    reward: {
                        ...state.history.reward,
                        temp: state.history.reward.origin,
                        filter: initState.history.reward.filter
                    }
                }
            }
        case Type.ADD_STAMP_HISTOR:
            return {
                ...state,
                history: {
                    ...state.history,
                    stamp: {
                        ...state.history.stamp,
                        origin: action.payload,
                        temp: action.payload
                    }
                },
                fetchedStamp: true
            }
        case Type.ADD_STAMP_FILTER_RANGE:
            return {
                ...state,
                history: {
                    ...state.history,
                    stamp: {
                        ...state.history.stamp,
                        temp: state.history.stamp.origin.filter(r => r.createdAt >= action.payload.from && r.createdAt <= action.payload.to),
                        filter: {
                            from: action.payload.from,
                            to: action.payload.to
                        }
                    }
                }
            }
        case Type.CLEAR_STAMP_FILTER:
            return {
                ...state,
                history: {
                    ...state.history,
                    stamp: {
                        ...state.history.stamp,
                        temp: state.history.stamp.origin,
                        filter: initState.history.stamp.filter
                    }
                }
            }
        case Type.ADD_CAR:
            return {
                ...state,
                car: {
                    ...state.car,
                    cars: action.payload,
                },
                fetchedCar: true,
            }
        default:
            return state
    }
}
