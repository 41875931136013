import * as React from "react";
import 'antd/dist/antd.css';
import moment from 'moment';
import { Row, Col, Avatar, Card, Button } from "antd";
import * as Actions from '../redux/actions'
import { HighStockChart, HighBarChart, HighPieChart as PieChart, HighNegativeBarChart } from '../HighCharts'
//import { HeatMapChart as HeatMap } from '../HighCharts'
import PanelBox from "../PanelBox/PanelBox";
import * as Colors from '../../color'
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import DateRange from '../../report/Member/component/dateRange'
import { trendConfig, pointAndMemberMonthlyConfig, pieConfig, MonthlyRedeemAndRewardConfig, pointFilter } from './model'
const dateFormat = 'YYYY/MM/DD';
//import { heatMapConfig } from "./model";
class PointChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pending: false,
            origin: {
                summary: {
                    totalMember: 0, earnPoint: 0, spendPoint: 0, earnEngage: 0, spendEngage: 0
                },
                pointTrend: [],
                monthlyMemberAndPoint: []
            },
            data: {
                summary: {
                    totalMember: 0, earnPoint: 0, spendPoint: 0, earnEngage: 0, spendEngage: 0
                },
                pointTrend: [],
                monthlyMemberAndPoint: []
            },
            sdate: moment().startOf('month'),
            tdate: moment().endOf('month')
        }
        this.init = this.init.bind(this)
        this.onpresetController = this.onpresetController.bind(this)
        this.onOk = this.onOk.bind(this)
        this.onhandleChange = this.onhandleChange.bind(this)
        this.onClear = this.onClear.bind(this)
    }
    init(props) {
        const { fetchedPointMonthly, fetchedPointTrend, fetchedSummary, fetchedLastMonth, merchantId } = props
        const { summary, trend, monthly, lastMonth } = props
        const { pending } = this.state
        if (!pending) {
            this.setState({
                pending: true
            }, () => {
                if (!fetchedPointTrend) trend(merchantId)
                if (!fetchedSummary) summary(merchantId)
                if (!fetchedPointMonthly) monthly(merchantId)
                if (!fetchedLastMonth) lastMonth(merchantId, 3)
            })
        }
        if (fetchedSummary && fetchedPointTrend && fetchedPointMonthly) {
            const { summaryData, trendData, monthlyData } = props
            const { totalMember = 0, earnPoint = 0, spendPoint = 0, earnEngage = 0, spendEngage = 0 } = summaryData
            const _newObj = {
                summary: {
                    totalMember: totalMember, earnPoint: earnPoint, spendPoint: spendPoint, earnEngage: earnEngage, spendEngage: spendEngage
                },
                pointTrend: trendData,
                monthlyMemberAndPoint: monthlyData
            }
            this.setState({
                origin: { ..._newObj },
                data: { ..._newObj }
            })
        }
    }
    componentDidMount() {
        this.init(this.props)
    }
    componentWillReceiveProps(nextProps) {
        this.init(nextProps)
    }
    onhandleChange(m, st) {
        this.setState({
            sdate: moment(st[0]),
            tdate: moment(st[1])
        })
    }
    onpresetController(m, st) {
        const { pointTrend, monthlyMemberAndPoint } = this.state.origin
        //const oux = moment(st[0]).toDate()
        //const unix = moment(st[0]).unix()
        //1607299200
        //1604810710
        this.setState({
            sdate: st[0],
            tdate: st[1],
            data: pointFilter({ pointTrend: pointTrend, monthlyMemberAndPoint: monthlyMemberAndPoint, from: st[0], to: st[1] })
        })
    }
    onOk() {
        const { pointTrend, monthlyMemberAndPoint } = this.state.origin
        const { sdate, tdate } = this.state
        const _newObj = { pointTrend: pointTrend, monthlyMemberAndPoint: monthlyMemberAndPoint, from: sdate, to: tdate }
        this.setState({
            data: pointFilter({ ..._newObj })
        })
    }
    onClear() {
        const { origin } = this.state
        this.setState({
            data: { ...origin }
        })
    }
    render() {
        //  const { lastMonthData, fetchedLastMonth } = this.props
        const { fetchedPointMonthly, fetchedPointTrend, fetchedSummary } = this.props
        const trendData = this.state.data.pointTrend
        const monthlyData = this.state.data.monthlyMemberAndPoint
        const { totalMember, earnPoint, spendPoint, earnEngage, spendEngage } = this.state.data.summary
        return (
            <div className='point'>
                <div >
                    <Row gutter={24}>
                        <Col span={24}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div >
                                    <DateRange
                                        format={dateFormat}
                                        defaultValue={[this.state.sdate, this.state.tdate]}
                                        onChange={(s, t) => this.onhandleChange(s, t)}
                                        onOk={() => this.onOk()}
                                        footerController={(m, st) => this.onpresetController(m, st)}
                                        showTime
                                    />
                                </div>
                                <div style={{ marginLeft: '10px', width: 'auto' }}>
                                    <Button icon='delete' onClick={() => this.onClear()} style={{ width: '100%' }} block>Clear Filter</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='point__bar' style={{ marginTop: '12px' }}>
                    <Row gutter={10} >
                        <Col span={6}>
                            <PanelBox className="card-item" loading={!fetchedSummary}>
                                <div style={{ display: "flex", height: 50 }}>
                                    <div style={{ color: 'blue' }}>
                                        <Avatar icon="team" size={64} style={{ color: 'black', backgroundColor: 'white' }} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <ul>
                                            <li style={{ color: 'black', fontSize: 16 }}>Total Members</li>
                                            <li style={{
                                                color: Colors.LabelColor
                                            }}> {totalMember}</li>
                                        </ul>
                                    </div>

                                </div>
                            </PanelBox>
                        </Col>
                        <Col span={6}>
                            <PanelBox className="card-item" loading={!fetchedSummary}>
                                <div style={{ display: "flex", height: 50 }}>
                                    <div style={{ color: 'blue' }}>
                                        <Avatar icon="shopping-cart" size={64} style={{ color: 'black', backgroundColor: 'white' }} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <ul>
                                            <li style={{ color: 'black', fontSize: 16 }}> Redeem Points</li>
                                            <li style={{
                                                color: Colors.LabelColor
                                            }}>{spendPoint}</li>
                                        </ul>
                                    </div>

                                </div>
                            </PanelBox>
                        </Col>
                        <Col span={6}>
                            <PanelBox className="card-item" loading={!fetchedSummary}>
                                <div style={{ display: "flex", height: 50 }}>
                                    <div style={{ color: 'blue' }}>
                                        <Avatar icon="gift" size={64} style={{ color: 'black', backgroundColor: 'white' }} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <ul>
                                            <li style={{ color: 'black', fontSize: 16 }}>Reward Points</li>
                                            <li style={{
                                                color: Colors.LabelColor
                                            }}> {earnPoint}</li>
                                        </ul>
                                    </div>
                                </div>
                            </PanelBox>
                        </Col>
                        <Col span={6}>
                            <PanelBox className="card-item" loading={!fetchedSummary}>
                                <div style={{ display: "flex", height: 50 }}>
                                    <div style={{ color: 'blue' }}>
                                        <Avatar icon="swap" size={64} style={{ color: 'black', backgroundColor: 'white' }} />
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <ul>
                                            <li style={{ color: 'black', fontSize: 16 }}>Engagement</li>
                                            <li style={{
                                                color: Colors.LabelColor
                                            }}> {earnEngage}</li>
                                            <li style={{
                                                color: Colors.WatermelonColor
                                            }}> {spendEngage}</li>
                                        </ul>
                                    </div>
                                </div>
                            </PanelBox>
                        </Col>
                    </Row>
                </div>
                <div className='point__card'>
                    <Row gutter={24}>
                        <Col lg={16} md={24}>
                            <Card
                                loading={!fetchedPointTrend}
                                bordered={false}
                                bodyStyle={{
                                    padding: '24px 36px 24px 0',
                                }}>
                                <HighStockChart config={trendConfig(trendData)} />
                            </Card>
                        </Col>
                        <Col lg={8} md={24} >
                            <Card loading={!fetchedSummary} >
                                <PieChart config={pieConfig(this.state.data.summary)} />
                            </Card>
                        </Col>
                        <Col lg={12} md={24} style={{ marginTop: '10px' }} >
                            <Card loading={!fetchedPointMonthly} >
                                <HighBarChart config={pointAndMemberMonthlyConfig(monthlyData)} />
                            </Card>
                        </Col>
                        {/* <Col lg={12} md={24} style={{ marginTop: '10px' }}>
                            <Card bordered={false}
                                loading={!fetchedLastMonth}
                            >
                                <HeatMap config={heatMapConfig(lastMonthData)} />
                            </Card>
                        </Col> */}
                        <Col lg={12} md={24} style={{ marginTop: '10px' }}>
                            <Card bordered={false}
                                loading={!fetchedPointMonthly}
                            >
                                <HighNegativeBarChart config={MonthlyRedeemAndRewardConfig(monthlyData)} />
                            </Card>
                        </Col>
                    </Row>
                </div>
                {/* <div className="point__footer"  style={{marginTop:'10px'}}>
                    <Row gutter={10}>
                        <Col span={8}>
                            <Card>
                                <PointShowMore />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card>
                                <PointShowMore />
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card>
                                <PointShowMore />
                            </Card>
                        </Col>
                    </Row>
                </div> */}
            </div >
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        summary: (merchantId) => dispatch(Actions.GetPointMemberSummary(merchantId)),
        trend: (merchantId) => dispatch(Actions.GetPointTrend(merchantId)),
        monthly: (merchantId) => dispatch(Actions.GetPointAndMemberMonthly(merchantId)),
        lastMonth: (merchantId, monthNo) => dispatch(Actions.GetPointLastMonth(merchantId, monthNo))
    }
}
const mapStateToProps = state => {
    const { chartState, authState } = state
    const { summary, chart, fetchedSummary, fetchedPointTrend, fetchedPointMonthly, fetchedLastMonth } = chartState
    const { point } = chart
    const { trend, monthly, lastMonth } = point
    return {
        summaryData: summary,
        trendData: trend,
        monthlyData: monthly,
        lastMonthData: lastMonth,
        fetchedSummary: fetchedSummary,
        fetchedPointTrend: fetchedPointTrend,
        fetchedPointMonthly: fetchedPointMonthly,
        fetchedLastMonth: fetchedLastMonth,
        merchantId: authState.status === 'loggedIn' && state.authState.userInfo ? authState.userInfo.merchantId : ''
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PointChart));