import { Layout } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Dispatch } from 'redux';
import { userIsAuthenticated } from './authWrapper';
import { SideNav, TopBar } from './layout';
import { AppState } from './store';
import Log from "./Logs/LogTable";
import { PointChart, CustomerChart, RewardChart } from './Charts';
import { PointHistory, StampHistory, RewardHistory, AuditHistory, ExpiePointList } from './report'
import { AllMemberList, TopMemberList, NewMemberList, TierMemberList } from './report'
import { ActiveMemberList, InactiveMemberList, RoyalMemberList, MemberDetail } from './report'
import { RedMemberList, SleepMemberList, RiskMemberList, ReturnMemberList } from './report'
/*global __COMMIT_HASH__*/
/*eslint no-undef: "error"*/

const { Header, Content, Footer } = Layout;
const mapStateToProps = (state: AppState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch, { history }: Props) => {
  return {
    onMenuClick: (item: { key: string }) => {
      history.push(`/${item.key}`);
    },
  };
};

interface Props extends RouteComponentProps<{}> {
  onMenuClick: (item: { key: string }) => void;
}

class Root extends React.Component<Props> {
  render() {
    const { onMenuClick, location } = this.props;
    const currentMenuItem =
      (location && location.pathname.split('/')[1]) || 'dashboard';

    return (
      <div className="App">
        <Layout style={{ minHeight: '100vh' }}>
          <SideNav
            onMenuClick={onMenuClick}
            currentMenuItem={currentMenuItem}
          />
          <Layout>
            <Header>
              <TopBar />
            </Header>
            <Content>
              <Switch>
                <Route exact={true} path="/" component={userIsAuthenticated(PointChart)} />
                <Route path="/dashboard/point" component={userIsAuthenticated(PointChart)} />
                <Route path="/dashboard/member" component={userIsAuthenticated(CustomerChart)} />
                <Route path="/dashboard/reward" component={userIsAuthenticated(RewardChart)} />
                <Route path="/report/history/audit" component={userIsAuthenticated(AuditHistory)} />
                {/* <Route path="/report/member" component={userIsAuthenticated(MemberList)} /> */}
                <Route path="/member/all" component={userIsAuthenticated(AllMemberList)} />
                <Route path="/member/active" component={userIsAuthenticated(ActiveMemberList)} />
                <Route path="/member/new" component={userIsAuthenticated(NewMemberList)} />
                <Route path="/member/return" component={userIsAuthenticated(ReturnMemberList)} />
                <Route path="/member/tier" component={userIsAuthenticated(TierMemberList)} />
                <Route path="/member/top" component={userIsAuthenticated(TopMemberList)} />
                <Route path="/member/inactive" component={userIsAuthenticated(InactiveMemberList)} />
                <Route path="/member/Loyal" component={userIsAuthenticated(RoyalMemberList)} />
                <Route path="/member/risk" component={userIsAuthenticated(RiskMemberList)} />
                <Route path="/member/sleep" component={userIsAuthenticated(SleepMemberList)} />
                <Route path="/member/red" component={userIsAuthenticated(RedMemberList)} />
                <Route path="/member/detail/:memberId" component={userIsAuthenticated(MemberDetail)} />
                <Route path="/report/history/point" component={userIsAuthenticated(PointHistory)} />
                <Route path="/report/history/expire" component={userIsAuthenticated(ExpiePointList)} />
                <Route path="/report/history/stamp" component={userIsAuthenticated(StampHistory)} />
                <Route path="/report/history/reward" component={userIsAuthenticated(RewardHistory)} />
                <Route path="/log/:logKey" component={Log} />
              </Switch>
            </Content>
            <Footer>
              <span>
                &copy; {new Date().getFullYear()}{' '}
                <a href="https://datafocus.cloud/">Data Focus Pte Ltd</a>
              </span>
              <span id="commit-hash">{__COMMIT_HASH__}</span>
            </Footer>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Root);
