import * as Type from './actionTypes'
import config from '../../config'
import { Console } from '../../console';
const report_url = process.env.REPORT_URL || config().config.Report.url

export const fetchCars = () => dispatch => {
    let _url = `${report_url}/cars`
    Console(`Car report Url  ${_url}`)
    fetch(_url, {
        crossDomain: true,
    })
        .then(response => response.json())
        .then(data => {
            let _action = {}
            _action = { ..._action, type: Type.ADD_CAR, payload: [] }
            if (data.status !== 'Error' || data.data.length < 0) {
                _action = { ..._action, payload: data.data || [] }
            }
            dispatch(_action)
        });
}


//expected parms milliseconds [from ,to ]
