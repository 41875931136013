import * as Type from './actionType'
import config from '../../config'
import { Console } from "../../console";
const report_url = process.env.REPORT_URL || config().config.Report.url
export { GetAllMember } from '../../report/redux/actions'
export const GetMemberTrend = merchantId => (dispatch) => {
    let _url = `${report_url}/trend/member/${merchantId}`
    Console(`Request Url, ${_url}`).log()
    fetch(_url, {
        crossDomain: true
    })
        .then(response => response.json())
        .then(data => {
            Console(`Memer Trend data ", ${data}`)
            let _trend = { memberTrend: [] }, _action = {}
            _action = { ..._action, type: Type.ADD_MEMBER_TREND, payload: { ..._trend } }
            if (data.status !== 'Error' || data.data.length < 0) {
                _action = { ..._action, payload: { ..._trend, memberTrend: data.data || [] } }
            }
            dispatch(_action)
        });
}

export const GetTopMemberSummary = merchantId => (dispatch) => {
    let _url = `${report_url}/member/topMemberSummary/${merchantId}`
    Console(`Request Url, ${_url}`)
    fetch(_url, {
        crossDomain: true
    })
        .then(response => response.json())
        .then(data => {
            Console(`Top member summary data , ${data}`)
            let _topMember = { topMember: [] }, _action = {}
            _action = { ..._action, type: Type.ADD_TOP_MEMBER, payload: { ..._topMember } }
            if (data.status !== 'Error' || data.data.length < 0) {
                _action = { ..._action, payload: { ..._topMember, topMember: data.data || [] } }
            }
            dispatch(_action)
        });
}
